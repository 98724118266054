<template>
    <div ref="pickerRef" class="v-tree-select-picker">
        <VField class="v-tree-select-picker-field" :icon="fieldIcon" :class="[{
            'v-tree-select-picker-field-active': isOpen
        }]" :placeholder="placeholder" :value="modelValueText" clearable :arrowIcon="arrowIcon" @click.native="open"
            @clear="handlerClear" />

        <client-only>
            <Portal v-if="portal" :selector="portal">
                <div v-if="isOpen" ref="panelRef" :class="panelClass" class="relative">
                    <TreeSelect :main-active-id.sync="viewMainActiveId" v-model="viewActiveId" :items="items"
                        @select="onSelect" @clear="handlerClear" />
                    <!-- <button class="v-tree-select-picker-clear"
                        type="button" @click="handlerClear">清除</button> -->
                </div>
            </Portal>
            <Transition v-else name="el-zoom-in-top">
                <div ref="panelRef" v-if="isOpen" class="v-tree-select-picker-panel relative">
                    <TreeSelect :main-active-id.sync="viewMainActiveId" v-model="viewActiveId" :items="items"
                        @select="onSelect" @clear="handlerClear" />
             
                </div>
            </Transition>
        </client-only>
</div>
</template>

<script>
import {
    computed,
    defineComponent,
    ref,
    unref,
    nextTick,
    toRef
} from "@nuxtjs/composition-api";
import { onClickOutside, useEventListener } from "@vueuse/core";
import VField from '~/components/VField/index.vue'
import TreeSelect from './TreeSelect.vue';

import { Portal } from '@linusborg/vue-simple-portal'

export default defineComponent({
    name: 'TreeSelectPicker',
    components: { Portal, VField, TreeSelect },
    props: {
        placeholder: String,
        fieldIcon: String,

        items: {
            /**
            * @typedef TreeSelectItem
            * @property { string | number } id
            * @property { string } text
            * @prop { TreeSelectItem } children
            * 
            * @type { import('vue').PropType< TreeSelectItem[] > }
            */
            type: Array,
            default: () => []
        },
        panelClass: String,
        portal: String,
        mainActiveId: [Number, String],
        arrowIcon: {
            type: String,
            default: 'el-icon-arrow-right'
        },
        value: [String, Number]
    },
    emits: ['input', 'update:mainActiveId', 'change'],
    setup(props, {
        emit
    }) {
        // const mainActiveId = computed(() => props.mainActiveId);
        const viewMainActiveId = ref('');

        const activeId = computed(() => props.value);
        const viewActiveId = ref('');

        const modelValueText = computed(() => {
            const currentSlide = props.items.find(item => item.id === props.mainActiveId);
            if (!currentSlide) {
                return
            }

            const currentItem = currentSlide.children.find(item => item.id === unref(activeId));
            return currentItem ?
                (currentItem.id === '-1' ? currentSlide.text : [currentSlide.text, currentItem.text].join('/'))
                :
                (currentSlide ? currentSlide.text : '')
        })

        const isClient = process.client

        const pickerRef = ref();
        const panelRef = ref();
        const isOpen = ref(false);
        const open = () => {
            isOpen.value = true;
            viewMainActiveId.value = props.mainActiveId;
            viewActiveId.value = activeId.value;
        }

        const listener = event => {
            const pickerEle = unref(pickerRef);
            const panelEle = unref(panelRef);

            if (pickerEle === event.target || (pickerEle && pickerEle.contains(event.target)) || (panelEle && panelEle.contains(event.target))) {
                return
            }

            handlerClickOutside();
        }
        useEventListener(isClient ? window : undefined, 'click', listener);

        const handlerClickOutside = () => {
            isOpen.value = false;
        }


        function onSelect(item) {
            emit('input', item.id)
            emit('update:mainActiveId', viewMainActiveId.value)
            // activeIndex.value = viewMainActiveId.value;
            nextTick(() => {
                emit('change', item.id, viewMainActiveId.value);
                isOpen.value = false;
            })
        }


        const handlerClear = () => {
            emit('input', '')
            emit('update:mainActiveId', '')
            isOpen.value = false;
            nextTick(() => {
                emit('change');
            })
        }

        // const onUpdateMainIndex = (index) => {
        //     console.log(index)
        //     viewMainActiveId.value = index;
        // }

        return {
            // activeIndex,
            viewMainActiveId,
            viewActiveId,
            activeId,

            modelValueText,

            panelRef,
            pickerRef,

            isOpen,
            open,

            onSelect,

            handlerClear
            // onUpdateMainIndex
        }
    }
})
</script>
<style lang="scss">
.v-tree-select-picker {
  position: relative;
}
.v-tree-select-picker-field-active::after {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 94, 220, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
  --tw-border-opacity: 1;
  border-color: rgba(0, 94, 220, var(--tw-border-opacity));
}
.v-tree-select-picker-panel {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 1px;
  margin-top: 0.625rem;
  min-width: 100%;
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: 10;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
</style>