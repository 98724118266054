<template>
    <div class="v-field" @mouseenter="hovering = true" @mouseleave="hovering = false">
        <span v-if="icon" class="v-field-icon"><i :class="icon" /></span>
        <div class="v-field-content">
            <div class="v-field-value">{{ value || placeholder }}</div>
        </div>

        <div class="v-field-suffix">
            <span v-if="showClear" class="v-filed-suffix-icon transition-colors hover:text-primary" @click.stop="handlerClear">
                <i class="el-icon-circle-close" />
            </span>
            <span v-else class="v-filed-suffix-icon">
                <i :class="arrowIcon" />
            </span>
        </div>
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    ref,
    nextTick
} from "@nuxtjs/composition-api";
export default defineComponent({
    name: 'VField',
    props: {
        value: String,
        placeholder: String,
        icon: String,
        arrowIcon: {
            type: String,
            default: 'el-icon-arrow-right'
        },
        clearable: Boolean
    },
    emits: ['input', 'clear'],
    setup(props, {
        emit
    }) {
        const hovering = ref(false);
        const showClear = computed(() => !!props.value && hovering.value && props.clearable)

        const handlerClear = () => {
            emit('input', '')
            nextTick(() => {
             emit('clear');
            })
        }
        return {
            hovering,
            showClear,

            handlerClear
        }
    }
})
</script>

<style lang="scss">
.v-field {
  border-radius: 0.25rem;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  line-height: 1.5;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  width: 100%;
}
.v-field::after {
  content: '';
  --tw-border-opacity: 1;
  border-color: rgba(25, 25, 25, var(--tw-border-opacity));
  border-radius: inherit;
  border-width: 1px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
}
.v-field::after:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 94, 220, var(--tw-border-opacity));
}
.v-field-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.v-field-value {
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.v-field-suffix {
  --tw-text-opacity: 1;
  color: rgba(96, 99, 112, var(--tw-text-opacity));
}
</style>