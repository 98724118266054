<template>
    <div class="v-tree-select">
        <div ref="parentRef" class="v-tree-select__nav">
            <el-scrollbar :view-style="{
                'max-height': height
            }">
                <!-- <div > -->
                <div v-for="(nav, navIndex) in items" :key="nav.id || navIndex" class="v-tree-select__nav-item" :class="{
                    'v-tree-select__nav-item--selected': mainActiveIndex === navIndex
                }" :title="nav.text" @click="handlerSlideBarItemClick(nav.id)">
                    <div class="v-tree-select__nav-item__text">
                        {{ nav.text }}
                    </div>
                </div>
                <!-- </div> -->
            </el-scrollbar>
        </div>

        <div ref="childRef" v-if="currentItem.children" class="v-tree-select__content">
            <el-scrollbar :view-style="{
                'max-height': height
            }">
                <div v-for="(item, index) in currentItem.children" :key="item.id || index" class="v-tree-select__item"
                    :class="{
                        'v-tree-select__item--active': mainActiveId === item.parent_id && item.id === activeId
                    }" @click="handleItemSelect(item)" :title="item.text">

                    <div class="line-clamp-1">{{ item.text }}</div>
                    <button v-if="mainActiveId === item.parent_id && item.id === activeId" class="v-tree-select__item-clear" type="button" @click="handlerClear">清除</button>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    onMounted,
    ref,
    toRef,
} from "@nuxtjs/composition-api";
import useScrollParent, { getScrollParent } from "~/composables/useScrollParent";

import scrollIntoView from '~/utils/scroll-into-view'

export default defineComponent({
    name: 'TreeSelect',
    emits: ['input', 'update::main-active-id', 'change', 'select'],
    props: {
        mainActiveId: {
            type: [Number, String],
            default: 0
        },
        value: {
            type: [String, Number],
            default: ''
        },
        items: {
            type: Array,
            default: () => []
        },
        height: {
            type: String,
            default: '338px'
        }
    },
    setup(props, { emit }) {

        const viewMainActiveId = ref(props.mainActiveId);
        const mainActiveIndex = computed(() => {
            const index = props.items.findIndex(item => item.id === viewMainActiveId.value);

            return index >= 0 ? index : 0;
        })
        const activeId = toRef(props, 'value');

        const handlerSlideBarItemClick = (id) => {
            viewMainActiveId.value = id;
            // emit('update:main-active-id', id);
        }

        const handleItemSelect = (item => {
            if(item.id === props.value && props.mainActiveId === viewMainActiveId.value){
                return
            }
            emit('update:main-active-id', props.items[mainActiveIndex.value].id);
            emit('input', item.id);
            emit('select', item);
        })


        const handlerClear = () => {
           emit('clear')
        }

        const currentItem = computed(() => props.items[mainActiveIndex.value] || {});

        /** @type {import('vue').Ref<HTMLDivElement>} */
        const childRef = ref();
        const parentRef = ref();

        // const childScrollParnet = useScrollParent(childRef);
        // const parentScrollParnet= useScrollParent(parentRef);

        const scrollToOption = () => {
            if (childRef.value) {
                const scrollParent = childRef.value.querySelector('.el-select-dropdown__wrap');
                const target = childRef.value.querySelector('.v-tree-select__item--active');
                scrollParent && scrollIntoView(scrollParent, target);
            }
            if (parentRef.value) {
                const scrollParent = parentRef.value.querySelector('.el-select-dropdown__wrap');
                const target = parentRef.value.querySelector('.v-tree-select__item--active');
                scrollParent && scrollIntoView(scrollParent, target);
            }
        }

        onMounted(() => {
            scrollToOption();
        })

        return {
            childRef,
            parentRef,
            mainActiveIndex,
            currentItem,
            activeId,

            handlerSlideBarItemClick,
            handleItemSelect,
            handlerClear
        }
    }
})
</script>

<style lang="scss">
.v-tree-select {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  min-width: 14.5rem;
  overflow: hidden;
  position: relative;
}
.v-tree-select__nav {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 248, var(--tw-bg-opacity));
  width: 128px;
}
.v-tree-select__nav-scroller {
  height: 100%;
}
.v-tree-select__nav-item {
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-tree-select__nav-item:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
.v-tree-select__nav-item--selected, .v-tree-select__nav-item:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
.v-tree-select__nav-item__text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.v-tree-select__content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
  width: 13.5rem;
}
.v-tree-select__item {
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 3.25rem;
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(25, 25, 25, var(--tw-text-opacity));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
}
.v-tree-select__item-clear {
  --tw-border-opacity: 1;
  border-color: rgba(151, 154, 168, var(--tw-border-opacity));
  border-radius: 0.125rem;
  border-width: 1px;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(96, 99, 112, var(--tw-text-opacity));
}
.v-tree-select__item--active, .v-tree-select__item:active {
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(0, 94, 220, var(--tw-text-opacity));
}
</style>