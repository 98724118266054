var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"v-tree-select"},[_c('div',{ref:"parentRef",staticClass:"v-tree-select__nav"},[_c('el-scrollbar',{attrs:{"view-style":{
            'max-height': _vm.height
        }}},_vm._l((_vm.items),function(nav,navIndex){return _c('div',{key:nav.id || navIndex,staticClass:"v-tree-select__nav-item",class:{
                'v-tree-select__nav-item--selected': _vm.mainActiveIndex === navIndex
            },attrs:{"title":nav.text},on:{"click":function($event){return _vm.handlerSlideBarItemClick(nav.id)}}},[_c('div',{staticClass:"v-tree-select__nav-item__text"},[_vm._v("\n                    "+_vm._s(nav.text)+"\n                ")])])}),0)],1),_vm._v(" "),(_vm.currentItem.children)?_c('div',{ref:"childRef",staticClass:"v-tree-select__content"},[_c('el-scrollbar',{attrs:{"view-style":{
            'max-height': _vm.height
        }}},_vm._l((_vm.currentItem.children),function(item,index){return _c('div',{key:item.id || index,staticClass:"v-tree-select__item",class:{
                    'v-tree-select__item--active': _vm.mainActiveId === item.parent_id && item.id === _vm.activeId
                },attrs:{"title":item.text},on:{"click":function($event){return _vm.handleItemSelect(item)}}},[_c('div',{staticClass:"line-clamp-1"},[_vm._v(_vm._s(item.text))]),_vm._v(" "),(_vm.mainActiveId === item.parent_id && item.id === _vm.activeId)?_c('button',{staticClass:"v-tree-select__item-clear",attrs:{"type":"button"},on:{"click":_vm.handlerClear}},[_vm._v("清除")]):_vm._e()])}),0)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }